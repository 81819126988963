<template>
  <div class="p-grid mail-box">
    <div v-if="profiles.length !== 0 || loading" class="p-col-12">
      <view-switcher
        :layout="layout"
        :loading="loading"
        :length="profiles.length"
        @toggleView="toggleView"
      />
      <div>
        <list-view
          v-if="layout === 'list'"
          :profiles="profiles"
          :loading="loading"
          :profile-number="profileNumber"
          :load-profiles="loadProfiles"
          @updateMails="removeFromMails"
          @blockUser="blockUser"
        >
          <!-- deleted -->
          <template #deletedProfile="{}">
            <div class="list-item">
              <div class="item-itself">
                <p class="for-text-list for-text-list-del p-p-0">
                  This profile is deleted
                </p>
              </div>
            </div>
          </template>
          <template #activeProfile="{ profile }">
            <div
              v-if="selectedContact !== profile.data.attributes.id"
              class="list-item footer-content-list"
            >
              <div
                v-if="profile.data.attributes.status === 'requested'"
                class="item-itself for-text-list-req p-text-center"
              >
                Awaiting response
              </div>
              <div
                v-else-if="profile.data.attributes.status === 'rejected'"
                class="item-itself for-text-list-rej p-text-center"
              >
                Rejected
              </div>
              <div
                v-else-if="profile.data.attributes.status === 'accepted'"
                class="item-itself for-text-list-acc p-text-center"
              >
                Accepted
              </div>
            </div>
            <div
              v-if="
                profile.data.attributes.status === 'accepted' &&
                selectedContact !== profile.data.attributes.id
              "
              class="list-item"
            >
              <div class="item-itself">
                <i
                  v-tooltip.top="'Display number'"
                  class="fas fa-phone-alt p-ml-1 mobile-list call-round"
                  @click="showMobileNumber(profile.data.attributes.id)"
                ></i>
              </div>
            </div>
            <!-- displaying number -->
            <div
              v-if="
                profile.data.attributes.status === 'accepted' &&
                showNumber &&
                selectedContact === profile.data.attributes.id
              "
              class="list-item mob-num-trans-list"
            >
              <div class="item-itself">
                <i class="fas fa-phone-alt p-ml-1 p-mr-2"></i>
                <p
                  v-tooltip.top="
                    clipBoard ? 'Click to copy' : 'Copied to clipboard'
                  "
                  class="p-my-0 p-mx-1"
                  @click="
                    copyToClipboard(profile.data.attributes.user_contact_number)
                  "
                >
                  {{ profile.data.attributes.user_contact_number }}
                </p>
              </div>
            </div>
          </template>
        </list-view>
        <grid-view
          v-else
          :profiles="profiles"
          :loading="loading"
          :profile-number="profileNumber"
          :load-profiles="loadProfiles"
          @blockUser="blockUser"
        >
          <template #default="{ profile }">
            <div
              v-if="!profile.data.attributes.user_is_active"
              class="p-d-flex p-jc-between footer-content"
            >
              <div class="p-d-flex p-jc-center pad">
                <p class="for-text-del">This profile is deleted</p>
              </div>
            </div>
            <div
              v-else-if="profile.data.attributes.status === 'requested'"
              class="p-d-flex p-jc-between footer-content"
            >
              <div class="p-d-flex p-jc-center pad">
                <p class="for-text-req">Awaiting response</p>
              </div>
            </div>
            <div
              v-else-if="profile.data.attributes.status === 'rejected'"
              class="p-d-flex p-jc-between footer-content"
            >
              <div class="p-d-flex p-jc-center">
                <p class="for-text-del p-text-center p-d-flex p-ai-center pad">
                  Rejected
                </p>
              </div>
            </div>
            <div v-else-if="profile.data.attributes.status === 'accepted'">
              <div class="footer-content">
                <div class="p-d-flex p-jc-between p-ai-center p-p-0 call-align">
                  <p
                    v-if="selectedContact !== profile.data.attributes.id"
                    class="for-text-acc p-my-0 pad p-pl-2"
                  >
                    Accepted
                  </p>
                  <div
                    :class="
                      showNumber &&
                      selectedContact === profile.data.attributes.id
                        ? 'p-d-flex p-jc-center p-ai-center mob-num-trans-grid'
                        : null
                    "
                  >
                    <i
                      v-if="selectedContact !== profile.data.attributes.id"
                      v-tooltip.top="
                        windowWidth ? 'Display number' : { disabled: true }
                      "
                      class="fas fa-phone-alt mobile-list p-mr-2"
                      @click="showMobileNumber(profile.data.attributes.id)"
                    />
                    <div
                      v-if="
                        showNumber &&
                        selectedContact === profile.data.attributes.id
                      "
                      class="p-d-flex p-jc-between p-p-0 p-ai-center mobile-num"
                    >
                      <i class="fas fa-phone-alt p-ml-1 p-mr-3"></i>
                      <p
                        v-tooltip.top="
                          clipBoard ? 'Click to copy' : 'Copied to clipboard'
                        "
                        @click="
                          copyToClipboard(
                            profile.data.attributes.user_contact_number
                          )
                        "
                      >
                        {{ profile.data.attributes.user_contact_number }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </grid-view>
      </div>
    </div>
    <div v-else class="p-col-12">
      <empty-mails
        :empty-text="'Currently you didn\'t sent any invitations.'"
        :img="'/sent.png'"
      />
    </div>
    <div v-if="showNumber" class="full-window" @click="hideMobleNumber" />
  </div>
</template>

<script>
import GridView from '@/components/views/GridView.vue';
import ListView from '@/components/views/ListView.vue';
import '@/assets/ListAndGridView.scss';
import EmptyMails from '@/components/EmptyMails.vue';
import { mailboxMixin } from '@/mixins/mailboxMixin';
import ViewSwitcher from '@/utilities/ViewSwitcher.vue';

export default {
  components: {
    GridView,
    ListView,
    EmptyMails,
    ViewSwitcher,
  },
  mixins: [mailboxMixin],
  data() {
    return {
      clipBoard: true,
      reqUrl: 'interests/show_sent_interests',
    };
  },
};
</script>
<style scoped>
.mobile-num {
  white-space: nowrap;
  overflow: hidden;
}
</style>
